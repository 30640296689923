import { FC } from 'react'
import {
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from '@mui/material'
import { KeyboardArrowRight, HelpOutlineRounded } from '@mui/icons-material'
import { useDocumentsList } from 'services/documents/documents.hooks'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useLanguage } from 'services/i18n/i18n.hooks'
import { useUserRoles, isOwnerOnly, shouldShowForOwner } from 'services/rbac/useUserRoles'

const DefectsList: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { language } = useLanguage()
  const { data: attachmentsList = [], isFetching, isLoading } = useDocumentsList()
  const userRoles = useUserRoles()
  const isOnlyOwner = isOwnerOnly(userRoles)

  const attachments = attachmentsList
    .filter(({ displayInHelp }) => displayInHelp)
    .filter((item) => !item.language || item.language.isoCode === language)
    .filter((item) => shouldShowForOwner(isOnlyOwner, item.visibleForOwner))
    .sort((a, b) => a.name.localeCompare(b.name))

  if (isFetching || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Tabs
          value='/help'
          onChange={(_, value) => navigate(value)}
          variant='scrollable'
          scrollButtons='auto'
          sx={{ marginTop: { xs: '75px', lg: '0px' }, mb: 3 }}>
          <Tab
            label={formatMessage({
              id: 'defectsList.help',
              defaultMessage: 'Pomoc'
            })}
            value='/help'
          />
          <Tab
            label={formatMessage({
              id: 'defectsList.faults',
              defaultMessage: 'Usterki'
            })}
            value='/faults'
          />
        </Tabs>

        <List
          sx={{
            width: '100%',
            '.MuiListItem-root:first-child': {
              borderTop: '1px solid #CDCDCD'
            }
          }}>
          {attachments.map(({ name, id }) => (
            <ListItem
              key={id}
              onClick={() => navigate(`/help/${id}`)}
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD'
              }}
              secondaryAction={<KeyboardArrowRight color='disabled' />}>
              <ListItemButton sx={{ py: 2 }}>
                <ListItemIcon>
                  <HelpOutlineRounded color='primary' />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default DefectsList
