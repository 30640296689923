import { useAccessToken } from 'services/token/token.hooks';
import { decodeToken } from 'utils/jwt';
import { OWNER } from './roles.const';

export const useUserRoles = () => {
  const { accessToken } = useAccessToken();
  
  if (!accessToken) return [];
  
  const decoded = decodeToken(accessToken);
  return decoded?.roles || [];
};

export const shouldShowForOwner = (isOnlyOwner: boolean, visibleForOwner: boolean | undefined) => {
  if (!isOnlyOwner) return true;
  return visibleForOwner !== false;
};

export const isOwnerOnly = (roles: string[]) => {
  return roles.length === 1 && roles.includes(OWNER);
};

export const isOwner = (roles: string[]) => {
  return !!roles.includes(OWNER);
};


