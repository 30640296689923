import { FC } from 'react'
import { Discount } from 'components/Discounts'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const DiscountsPage: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'discounts.discounts',
          defaultMessage: 'Rabaty'
        })}
        desktopNavigateTo='/'
      />
      <Discount />
    </UserProtectedRoute>
  )
}

export default DiscountsPage
