import { apiInstance } from 'services/api'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'
import { accessTokenAtom, refreshTokenAtom } from 'services/token/token.model'
import { clarity } from 'react-microsoft-clarity'
import { decodeToken } from 'utils/jwt'

interface UseObtainParams {
  onError: () => void
}

export const useObtain = ({ onError }: UseObtainParams) => {
  const setAccessToken = useSetAtom(accessTokenAtom)
  const setRefreshToken = useSetAtom(refreshTokenAtom)

  return useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      const emailToLowerCase = email.toLowerCase()
      return apiInstance<{ access: string; refresh: string }>({
        url: '/users/token/obtain/',
        method: 'POST',
        data: {
          email: emailToLowerCase,
          password
        }
      })
    },
    onSuccess: ({ data }) => {
      if (clarity.hasStarted()) {
        const decoded = decodeToken(data.access)
        if (decoded) {
          clarity.identify(`${decoded.user_id}`, { roles: decoded.roles })
          clarity.start()
        }
      }

      setAccessToken(data.access)
      setRefreshToken(data.refresh)
    },
    onError
  })
}

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
  const setRefreshToken = useSetAtom(refreshTokenAtom)

  const clearUserData = async () => {
    if (clarity.hasStarted()) {
      clarity.stop()
    }
    setAccessToken(null)
    setRefreshToken(null)
  }

  return { accessToken, clearUserData }
}
export const useRefreshToken = () => {
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom)

  const clearRefreshToken = () => {
    setRefreshToken(null)
  }

  return { refreshToken, clearRefreshToken }
}

export const useVerifyToken = () => {
  // const queryClient = useQueryClient();
  // const { clearUserData } = useAccessToken();

  return useMutation({
    mutationFn: (token: string) =>
      apiInstance<{ detail: string; code: string }>({
        url: '/users/token/verify/',
        method: 'POST',
        data: {
          token
        }
      }),
    onError: () => {}
  })
}

export const useUserPasswordReset = () => {
  return useMutation({
    mutationFn: ({ password, token }: { password: string; token: string }) =>
      apiInstance<any>({
        url: '/users/password/reset/',
        method: 'POST',
        data: { password, resetKey: token }
      })
  })
}
