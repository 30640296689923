import {
  CampaignOutlined,
  GridViewOutlined,
  HomeOutlined,
  HomeRepairServiceOutlined,
  PercentOutlined
} from '@mui/icons-material'
import { Avatar, BottomNavigation, BottomNavigationAction, Box, IconButton } from '@mui/material'
import TermsOfUse from 'components/TermsOfUse'
import BoxWithSafeZone from 'components/BoxWithSafeZone'
import { NotificationIndicator } from 'components/Notifications'
import { FC, ReactNode, useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useUsersProfile } from 'services/profile/profile.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import {
  CustomBottomNavigation,
  CustomHeaderNavigation,
  DashboardContent
} from './Dashboard.styled'
import { useIntl } from 'react-intl'
import { useUserRoles, isOwnerOnly } from 'services/rbac/useUserRoles'
import { OWNER_ALLOWED_ROUTES, isPathAllowed } from 'services/rbac/routes.const'

import Logo from 'static/images/Logo-R4R-Mark-Navy.png'

const Dashboard: FC<{ children: ReactNode }> = ({ children }) => {
  const { userInitials } = useUsersProfile()
  const navigation = useNavigate()
  const { pathname } = useLocation()
  const { hasUserConsented } = useTermsOfUse()
  const { formatMessage } = useIntl()
  const userRoles = useUserRoles()
  const isOnlyOwner = isOwnerOnly(userRoles)

  const currentButton = useMemo(() => {
    const patterns = [
      '/additional-services/*',
      '/faults/*',
      '/discounts/*',
      '/board/*',
      '/',
      '/offer/*'
    ]

    for (const pattern of patterns) {
      const possibleMatch = matchPath(pattern, pathname)
      if (possibleMatch !== null) {
        return possibleMatch.pathnameBase
      }
    }

    return null
  }, [pathname])

  if (!hasUserConsented) {
    return <TermsOfUse />
  }

  if (isOnlyOwner && !isPathAllowed(pathname, OWNER_ALLOWED_ROUTES)) {
    navigation('/faults')
    return null
  }

  return (
    <BoxWithSafeZone>
      <CustomHeaderNavigation>
        <Box>
          <IconButton onClick={() => navigation('/user')}>
            <Avatar>{userInitials}</Avatar>
          </IconButton>
        </Box>
        <Box
          sx={{
            img: {
              height: '65px',
              width: 'auto'
            }
          }}>
          <img src={Logo} alt='Resi4Rent Logo' />
        </Box>
        <Box>
          <NotificationIndicator />
        </Box>
      </CustomHeaderNavigation>
      <DashboardContent component='main'>{children}</DashboardContent>
      <CustomBottomNavigation>
        <BottomNavigation
          value={currentButton}
          onChange={(_, value) => {
            navigation(value)
          }}>
          <BottomNavigationAction
            value='/'
            icon={<GridViewOutlined />}
            label={formatMessage({
              id: 'discounts.panel',
              defaultMessage: 'Panel'
            })}
            showLabel
            sx={{
              display: isOnlyOwner ? 'none' : 'inline-flex'
            }}
          />
          <BottomNavigationAction
            value='/faults'
            icon={<HomeRepairServiceOutlined />}
            label={formatMessage({
              id: 'discounts.helpDesk',
              defaultMessage: 'HelpDesk'
            })}
            showLabel
          />
          <BottomNavigationAction
            value='/board'
            icon={<CampaignOutlined />}
            label={formatMessage({
              id: 'dashboard.board',
              defaultMessage: 'Tablica'
            })}
            showLabel
            sx={{
              display: isOnlyOwner ? 'none' : 'inline-flex'
            }}
          />
          <BottomNavigationAction
            value='/additional-services'
            icon={<HomeOutlined />}
            showLabel
            label={formatMessage({
              id: 'dashboard.services',
              defaultMessage: 'Umowy'
            })}
            sx={{
              display: isOnlyOwner ? 'none' : 'inline-flex'
            }}
          />
          <BottomNavigationAction
            value={'/discounts'}
            icon={<PercentOutlined />}
            showLabel
            label={formatMessage({
              id: 'dashboard.discounts',
              defaultMessage: 'Rabaty'
            })}
            sx={{
              display: isOnlyOwner ? 'none' : 'inline-flex'
            }}
          />
        </BottomNavigation>
      </CustomBottomNavigation>
    </BoxWithSafeZone>
  )
}

export default Dashboard
