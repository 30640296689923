import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBenefitsList } from 'services/benefits/benefits.hooks'
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,  
} from '@mui/material'
import { KeyboardArrowRight, DiscountOutlined } from '@mui/icons-material'
import InfoIndicator from 'components/InfoIndicator'
import NoActiveContractMessage from 'components/NoActiveContractMessage'
import { useIntl } from 'react-intl'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import Section from 'components/Section'

const DiscountsList: FC = () => {
  const { benefitsList = [], isFetching, isLoading } = useBenefitsList()
  const { formatMessage } = useIntl()
  const { leaseContractsList, isLoading: isLeaseLoading } = useLeaseContractsList()
  const navigate = useNavigate()

  const activeLeases = leaseContractsList.filter(({ leaseStatus }) => leaseStatus === 0)

  if (isLeaseLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  if (activeLeases.length === 0) {
    return (
      <NoActiveContractMessage />
    )
  }

  return (
    <>
      <List
        sx={{
          '.MuiListItem-root:first-of-type': {
            borderTop: '1px solid #CDCDCD'
          }
        }}>
        {benefitsList.map(({ id, name }) => (
          <ListItem
            key={id}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}
            onClick={() => navigate(`/discounts/${id}`)}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
        <InfoIndicator
          helperText={formatMessage({
            id: 'discountsList.noDiscounts',
            defaultMessage: 'Brak rabatów.'
          })}
          Icon={DiscountOutlined}
          isVisible={!benefitsList.length && !isFetching && !isLoading}
        />
      </List>
    </>
  )
}

export default DiscountsList
