import { FC } from 'react'
import { Tabs, Tab, Box, Typography, CircularProgress } from '@mui/material'
import Icon from 'components/Icon'
import { useNavigate } from 'react-router-dom'
import Dial from 'components/Dial'
import { useHelpdeskTicketsList } from 'services/helpdesk/hooks'
import dayjs from 'dayjs'
import InfoIndicator from 'components/InfoIndicator'
import NoActiveContractMessage from 'components/NoActiveContractMessage'
import { SosOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useUserRoles, isOwner } from 'services/rbac/useUserRoles'

const DefectsList: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { ticketsList, getSatusColors, isFetching, isLoading } = useHelpdeskTicketsList()
  const { leaseContractsList, isLoading: isLeaseLoading } = useLeaseContractsList()
  const userRoles = useUserRoles()
  const isOwnerR = isOwner(userRoles)

  const activeLeases = leaseContractsList.filter(({ leaseStatus }) => leaseStatus === 0)

  if (isLeaseLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  
  if (activeLeases.length === 0 && !isOwnerR) {
    return (
      <NoActiveContractMessage />
    )
  }

  return (
    <>
      <Box>
        <Tabs
          value='/faults'
          onChange={(_, value) => navigate(value)}
          variant='scrollable'
          scrollButtons='auto'
          sx={{ mb: 3 }}>
          <Tab
            label={formatMessage({
              id: 'defectsList.help',
              defaultMessage: 'Pomoc'
            })}
            value='/help'
          />
          <Tab
            label={formatMessage({
              id: 'defectsList.faults',
              defaultMessage: 'Usterki'
            })}
            value='/faults'
          />
        </Tabs>
        <InfoIndicator
          helperText={formatMessage({
            id: 'defectsList.noFaults',
            defaultMessage: 'Brak usterek'
          })}
          Icon={SosOutlined}
          isVisible={!ticketsList.length && !isLoading && !isFetching}
        />
        {ticketsList.map(({ id, status, createdAt, topic, faultCategory }) => (
          <Box
            onClick={() => navigate(`/faults/report/${id}`)}
            key={id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
              mb: 2,
              ':hover': {
                opacity: 0.6
              }
            }}>
            <Box
              sx={{
                width: '60px',
                height: '60px',
                borderRadius: '8px',
                backgroundColor: '#F2F6FF',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 1
              }}>
              <Icon name={faultCategory?.iconName ?? 'QuestionMark'} color='disabled' />
            </Box>
            <Box sx={{ flex: '1' }}>
              <Typography>{topic}</Typography>
              <Typography variant='body2'>
                {dayjs(createdAt).format('DD/MM/YYYY')}
                <Typography
                  variant='caption'
                  component='span'
                  sx={{
                    display: 'inline-flex',
                    borderRadius: '4px',
                    padding: '2px 8px',
                    fontWeight: 500,
                    marginLeft: '8px',
                    ...getSatusColors(status.code)
                  }}>
                  {status.name}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flexDirection: 'column',
                ml: 1
              }}>
              <Box></Box>
              <Typography variant='body2' fontWeight={500}></Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Dial ariaLabel=' ' onClick={() => navigate('/faults/report/add')} />
    </>
  )
}

export default DefectsList
