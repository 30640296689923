import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

type Language = {
  id: number
  name: string
  isoCode: string
}

type DocumentsListResponse = {
  id: number
  name: string
  file: string
  category: number
  displayInHelp: boolean
  language: null | Language
  movieUrl: null | string
  displayOnMainPageInClientPanel: boolean
  visibleForOwner: boolean
  visibleForTenant: boolean
}[]

export const useDocumentsList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['documentsList', masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<DocumentsListResponse>({
        url: '/documents/documents/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })
}
