import { FC } from 'react'
import { Typography, SxProps } from '@mui/material'
import { useIntl } from 'react-intl'

interface NoActiveContractMessageProps {
  sx?: SxProps
}

const NoActiveContractMessage: FC<NoActiveContractMessageProps> = ({ sx }) => {
  const { formatMessage } = useIntl()

  return (
    <Typography
      sx={{ fontWeight: 400, fontSize: '18px', textAlign: 'center', marginTop: '20px', ...sx }}>
      {formatMessage({
        id: 'general.noActiveContractInfo',
        defaultMessage: 'Ta opcja jest dostępna wyłącznie dla użytkowników z aktywną umową.'
      })}
    </Typography>
  )
}

export default NoActiveContractMessage