import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

interface UserFlatsResponse {
  id: number
  area: number
  floor: number
  flatIdNumber: string
  numberOfRooms: number
  flatType: number
  flatTypeExtra: number
  energyCertificate: string
  connectionType: string
}



export const useUserFlats = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: userFlats } = useQuery({
    queryKey: ['userFlatList', masquaradeToken],
    queryFn: () =>
      apiInstance<UserFlatsResponse[]>({
        url: `/helpdesk/user-flats/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { userFlats }
}

