export const OWNER_ALLOWED_ROUTES = [
  '/faults',
  '/help',
  '/user',
  '/notifications'
] as const;

export const isPathAllowed = (pathname: string, allowedRoutes: readonly string[]): boolean => {
  return allowedRoutes.some(route => pathname.startsWith(route));
};
